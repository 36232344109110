
.about-content{
    color:white;
    text-align: left;
}
.about-sub-title{
    padding-bottom: 15px;
}
.img-styles{
    width:70%;
    height:auto;
    border-radius: 10%;
    border: 2px solid #f3e98b;
    box-shadow: .5em .5em 1em black;
    margin-top: 30px;
}
.job-title{
    padding:10px;
    text-align: center;
}