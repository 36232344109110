.skills-container {
    color: #eed17a;
}

.bar-container {
    width: 100%;
    border: #494f54 solid 1px;
    height: auto;
    padding: 5px 0px 5px 5px;
    margin-bottom: 5px;
}

.bar-fill-container {
    background-color: transparent;
    padding: 0;
}

.bar-fill {
    background-color: #7de3a8;
    height: 100%;
    padding: 3px 0px 3px 0px;
}

.bar-text-container {
    background-color: #1f1f1f;
    border: 2px solid 262626;
    font-size: 1em;
}

.bar-text {
    color: #eed17a;
}

.invisible-placeholder {
    visibility: hidden;
}

.bar-header {
    font-size: 1.3em;
}

.dev-header {
    text-align: left;
    color: white;
    font-size: 1.5em;
    padding-bottom: 10px;
}

.accordion-item {
    background-color: transparent;
}

.accordion-button:not(.collapsed) {
    background-color: #846894;
    color: white;
    font-weight: bold;
    box-shadow: none;
}

.accordion-button:is(.collapsed) {
    background-color: #2c2c2c;
    color: #c895e8;
    box-shadow: none;
}
.percent-num{
    color: #7de3a8;
    font-family: Arial, Helvetica, sans-serif;
}

@media (max-width:1200px) {
    .percent-num {
        font-size: .7em;
    }
    .bar-text{
        font-size: .7em;
    }
    .dev-header {
        font-size: 1.3em;
    }
}
@media (max-width:992px) {
    .percent-num-container{
        display:none;
    }
}