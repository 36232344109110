.content-body{
    color:white;
}
#visitorForm, #thankYou {
  display: grid;
  place-items: center;
  height: 50vh;
}

.hidden {
  display: none;
}

form {
  display: grid;
  gap: 15px;
  text-align: left;
}
form input, form textarea {
    background-color: #333;
    color:white;
}

button {
  padding: 8px 15px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}
