.art-img {
    width:100%;
    height: auto;
}
.art-container{
    background-color: black;
    height: auto;
}
.react-multi-carousel-list{
    border: solid #7de3a8 1px;
}
.iframe{
    border: solid #7de3a8 1px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.iframe-container{
    position: relative;
    padding-top: 56.25%;
}
.video-helper{
    color:white;
    font-size: 1em;
}